// 
// user-variables.scss
// Use this to override Bootstrap and Cartzilla variables
//

// Example of a variable override to change Cartzilla background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

// Grayscale
$white:                 #fff !default;
$gray-100:              #f7f7fc !default;
$gray-200:              #f3f3f9 !default;
$gray-300:              #e9e9f2 !default;
$gray-400:              #dfdfeb !default;
$gray-500:              #9e9fb4 !default;
$gray-600:              #737491 !default;
$gray-700:            	#5a5b75 !default;
$gray-800:              #4a4b65 !default;
$gray-900:              #37384e !default;
$black:                 #000 !default;

// Brand colors
$primary:               #d32027 !default;
$secondary:             #ff4b46 !default;
$info:		            #10a6a8 !default;
$success:               #8adb2d !default;
$warning:               #fffa00 !default;
$danger:                #ff4425 !default;
$light:                 $gray-200 !default;
$dark:                  $gray-800 !default;

$path-to-fonts:'https://use.typekit.net/gex6ofu.css' !default;
$font-family-sans-serif: josefin-sans, sans-serif !default;
$font-family-base: josefin-sans, sans-serif !default;
$input-btn-font-family: josefin-sans, sans-serif !default;
$headings-font-family: cardo, sans-serif !default;

$font-weight-base: 300 !default;
$font-weight-medium: 400 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$headings-font-weight: 700 !default;
$display-font-weight:  400 !default;
$lead-font-weight: 300 !default;

$font-size-base: 1.2rem !default;